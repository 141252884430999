/**
 * @author qingliu
 * @email qingliu@yangqianguan.com
 * @date 2017-05-18 14:31:25.448
 * @desc generated by gulp
 */

export default {
    name: 'Repayment',

    title: 'Pusat Bantuan'
};
